/* Note: these styles will shared across radio, Radio and toggle components */
.inline-label {
  display: flex;
  align-items: center;
  gap: var(--inner-spacing-2);
  min-height: var(--sizing-4);
  position: relative;
  cursor: pointer;
  width: fit-content;

  /**
  * ----------------------------------------------------------------------------
  * LABEL POSITION
  *-----------------------------------------------------------------------------
  */
  &[data-label-position="left"] {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  /**
  * ----------------------------------------------------------------------------
  * DISABLED
  *-----------------------------------------------------------------------------
  */
  &[data-disabled]:not([data-field-type="field-group"] &) {
    opacity: var(--opacity-disabled);
    cursor: default;
  }
}
