.textarea {
  & [data-field-input] {
    block-size: auto;
  }

  & [data-field-input] textarea {
    height: auto;
    resize: none;
    min-block-size: var(--sizing-16);
    align-items: flex-start;
  }
}
